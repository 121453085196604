/** @jsx jsx */
import { jsx, css, withTheme } from "@delight-js/react";
import Link from "./Link";
import { Overline, H4 } from "./texts";
import Image from "./Image";

const ContentCard = withTheme(({ theme, overline, title, image, ...props }) => (
  <Link
    {...props}
    css={css`
      display: block;
      cursor: pointer;
      color: $color-spot1;
      text-decoration: none;
      transition: color 0.35s;

      .gatsby-image-wrapper {
        pointer-events: none;
      }

      @media:xl {
        align-items: center;
        .gatsby-image-wrapper {
          transition: transform 0.7s cubic-bezier(0.5, 1, 0.5, 1);
        }
      }

      &:hover {
        color: $color-spot2;

        @media:xl {
          .gatsby-image-wrapper {
            transform: scale3d(1.1, 1.1, 1);
          }
        }
      }
    `(theme)}
  >
    {!!image && !!image.childImageSharp && (
      <Image
        image={image.childImageSharp.gatsbyImageData}
        alt={title}
        sizes="(min-width: 1600px) 25vw, (min-width: 768px) 33vw, 66vw"
      />
    )}
    {!!overline && (
      <Overline
        css={css`
              $pt4;
              color: currentColor;

              a:hover & {
                filter: brightness(0.9);
              }
            `(theme)}
      >
        {overline}
      </Overline>
    )}
    {!!title && (
      <H4
        css={css`
              $pt2;
              color: currentColor;
              @media:lg {
                width: 90%;
              }
            `(theme)}
      >
        {title}
      </H4>
    )}
  </Link>
));

export default ContentCard;
