// Example from gatsby-plugin-portal
// https://www.gatsbyjs.com/plugins/gatsby-plugin-portal/?=gatsby-plugin-portal#gatsby-gotcha---document-is-undefined

import * as React from "react";
import ReactDOM from "react-dom";

const Portal = ({ id = "", children }) => {
  const wrapper = React.useRef();
  React.useEffect(() => {
    let baseElement = null;
    if (!wrapper.current) {
      baseElement = document.getElementById(id);
      if (!baseElement) {
        baseElement = document.createElement("div");
        baseElement.id = id;
        document.body.appendChild(baseElement);
      }
      wrapper.current = document.createElement("div");
      baseElement.appendChild(wrapper.current);
    }
    return () => {
      if (baseElement && wrapper.current) {
        baseElement.removeChild(wrapper.current);
        wrapper.current = null;
      }
    };
  }, [id]);
  if (wrapper.current) {
    return ReactDOM.createPortal(children, wrapper.current);
  }
  return null;
};

export default Portal;
