import * as React from "react";
import { withTheme, css } from "@delight-js/react";
import Slider from "./Slider";
import Grid from "./layout/Grid";
import Row from "./layout/Row";
import Cell from "./layout/Cell";
import ContentCard from "./ContentCard";
import { H1 } from "./texts";
import Button from "./Button";
import Link from "./Link";

const TeaserSlider = withTheme(
  ({ theme, headline, subline, items, button = {}, ...props }) => {
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    const prevDef = (e) => e.preventDefault();
    const { text: buttonText, ...buttonProps } = button;
    let renderedItems = !hasMounted ? (
      items.map(({ categories, imagePortrait, ...theProps }) => (
        <Cell
          span={{ default: 1, lg: 1 / 2, x4l: 1 / 3 }}
          key={theProps.to || theProps.title}
        >
          <ContentCard {...theProps} />
        </Cell>
      ))
    ) : (
      <Slider itemWidth={{ default: 8 / 10, lg: 4 / 10, x4l: 3 / 10 }}>
        {items.map(({ categories, imagePortrait, ...theProps }) => (
          <Cell
            key={theProps.to || theProps.title}
            css={css`
              /* @media:lg {
              position: relative;
              &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: calc($grid-gutter / 2);
                height: calc(100% - $grid-gutter - 0.2em);
                border-right: 1px solid $color-spot1;
              }
            } */
            `(theme)}
          >
            <ContentCard {...theProps} onDragStart={prevDef} />
          </Cell>
        ))}
      </Slider>
    );

    return (
      <Grid {...props}>
        <Row>
          <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
            <H1 as="h2">{headline}</H1>
          </Cell>
          {!!subline && (
            <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
              {subline}
            </Cell>
          )}
        </Row>
        <Row>
          <Cell
            span={{ default: 10 / 12 }}
            offset={{ default: 1 / 12 }}
            css={css`$mt6;`(theme)}
          >
            <Row>{renderedItems}</Row>
            {!!buttonText && (
              <Row css={css`$mt6;`(theme)}>
                <Cell>
                  <Button as={Link} {...buttonProps}>
                    {buttonText}
                  </Button>
                </Cell>
              </Row>
            )}
          </Cell>
        </Row>
      </Grid>
    );
  }
);

export default TeaserSlider;
