import * as React from "react";
import { css } from "@delight-js/react";
// import { Portal } from "@headlessui/react";
import Portal from "./Portal";
import dragSvg from "../images/icons/drag.svg";

const DragPointer = ({ show = false }) => {
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  React.useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({
        x: e.clientX,
        y: e.clientY,
      });
    };
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <Portal id="mouseportal">
      <div
        aria-hidden="true"
        css={css`
          position: fixed;
          left: -60px;
          top: -18px;
          pointer-events: none;
        `}
        style={{
          transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
        }}
      >
        <img
          src={dragSvg}
          alt=""
          css={css`
            transition: all 0.3s;
          `}
          style={{
            opacity: show ? 1 : 0,
            transform: `scale3d(${show ? "1, 1, 1" : "0, 0, 1"})`,
          }}
        />
      </div>
    </Portal>
  );
};

export default DragPointer;
