import * as React from "react";
import { useDeepCompareEffect } from "react-use";
import { useDrag } from "@use-gesture/react";
import { useSprings, animated } from "@react-spring/web";
import { css, withTheme } from "@delight-js/react";
import DragPointer from "./DragPointer";

const useBreakpoint = (breakpoints) => {
  const [breakpoint, setBreakpoint] = React.useState([breakpoints[0]]);
  useDeepCompareEffect(() => {
    const watchId = window.__WATCHERS__.size.subscribe({
      onchange([width]) {
        for (let i = 0, len = breakpoints.length; i < len; i++) {
          if (i === len - 1 || breakpoints[i + 1][1] > width) {
            setBreakpoint(breakpoints[i]);
            break;
          }
        }
      },
    });
    return () => {
      if (watchId) {
        window.__WATCHERS__.size.unsubscribe(watchId);
      }
    };
  }, [breakpoints]);
  return breakpoint;
};

const Slider = withTheme(({ children, theme, itemWidth, ...props }) => {
  const {
    definitions: { breakpoint },
  } = theme;
  const index = React.useRef(0);

  const [hovering, setHovering] = React.useState(false);
  const { length } = children;
  const setIndex = (newIndex) => {
    index.current = Math.min(Math.max(newIndex, 0), length - 1);
    return index.current;
  };
  const containerRef = React.useRef();
  const filteredBreakpoints = Object.entries(breakpoint).filter(
    (bp) => !!itemWidth[bp[0]]
  );
  // get current breakpoint for itemWidth config
  const [breakpointKey] = useBreakpoint(filteredBreakpoints);
  const [sliderWidthPx, setSliderWidthPx] = React.useState(0);
  const [
    firstNotHideableIndexRight,
    setFirstNotHideableIndexRight,
  ] = React.useState(length - 1);
  const itemWidthPx = breakpointKey
    ? sliderWidthPx * itemWidth[breakpointKey] || 0
    : 0;
  // calculate overlap on the right side
  const marginRight =
    breakpointKey && itemWidth[breakpointKey]
      ? Math.max(0, itemWidth[breakpointKey] * length - 1) * -1
      : 0;
  const isEdge = (index, direction) =>
    (index === 0 && direction > 0) ||
    (index === firstNotHideableIndexRight && direction < 0);

  const clampIndex = (newIndex) =>
    Math.max(0, Math.min(firstNotHideableIndexRight, newIndex));

  React.useEffect(() => {
    const watchId = window.__WATCHERS__.size.subscribe({
      onchange() {
        if (containerRef.current) {
          setSliderWidthPx(containerRef.current.parentElement.offsetWidth);
        }
      },
    });
    return () => {
      if (watchId) {
        window.__WATCHERS__.size.unsubscribe(watchId);
      }
    };
  }, [itemWidth]);

  React.useEffect(() => {
    setFirstNotHideableIndexRight(
      length - Math.floor(sliderWidthPx / itemWidthPx)
    );
  }, [sliderWidthPx, itemWidthPx, length]);

  const [springs, springsApi] = useSprings(children.length, () => ({
    x: Math.round(index.current * -itemWidthPx),
    scale: 1,
  }));
  const bind = useDrag(
    (input) => {
      const {
        event,
        active,
        movement: [mx],
        direction: [dx],
        cancel,
        last,
      } = input;
      if (!last && !active) return;
      event.preventDefault();
      //   event.stopPropagation();
      //   event.nativeEvent.stopImmediatePropagation();
      let isFinal = true;
      let currentIndex = index.current;
      const isEdgy = isEdge(currentIndex, mx);
      if (last) {
        currentIndex = setIndex(
          clampIndex(currentIndex - dx * Math.ceil(Math.abs(mx) / itemWidthPx))
        );
      } else {
        isFinal = false;

        if (isEdgy && Math.abs(mx) > sliderWidthPx / 3) {
          cancel();
        }
      }
      const startX = Math.max(
        marginRight * sliderWidthPx,
        currentIndex * -itemWidthPx
      );
      springsApi.start((i) => ({
        x: isFinal ? startX : startX + mx / (isEdgy ? 4 : 1),
        scale: isFinal ? 1 : isEdgy ? 0.95 : 0.9,
        immediate: (k) => k !== "scale" && active,
      }));
    },
    {
      filterTaps: true,
      axis: "x",
    }
  );
  const setHoveringTrue = () => setHovering(true);
  const setHoveringFalse = () => setHovering(false);

  React.useEffect(() => {
    springsApi.start((i) => ({
      x: Math.max(marginRight * sliderWidthPx, index.current * -itemWidthPx),
      scale: 1,
      immediate: true,
    }));
  }, [sliderWidthPx, itemWidthPx, marginRight, springsApi]);

  return (
    <React.Fragment>
      <div
        {...bind()}
        onMouseEnter={setHoveringTrue}
        onMouseLeave={setHoveringFalse}
        style={{ marginRight: `${marginRight * 100}%` }}
        css={css`
          display: flex;
          flex-grow: 1;
          touch-action: pan-y;
          -moz-user-select: none;
          -webkit-user-drag: none;
          user-select: none;

          img,
          a,
          picture,
          source {
            -moz-user-select: none;
            -webkit-user-drag: none;
            user-select: none;
          }
        `()}
        {...props}
        ref={containerRef}
      >
        {springs.map((styles, i) => (
          <animated.div
            key={i}
            style={{
              width: `${100 / children.length}%`,
              ...styles,
            }}
          >
            {children[i]}
          </animated.div>
        ))}
      </div>
      <DragPointer show={hovering} autoHide={true} />
    </React.Fragment>
  );
});

export default Slider;
